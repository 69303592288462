import { colors } from "../tokens";

export function EmailHighlightBlock() {
  return (
    <div
      style={{
        margin: "24px 0",

        padding: "20px",
        "background-color": colors.mutedColor,
        "border-radius": "5px",
      }}
    >
      <p style="margin: 0 0 12px 0; font-size: 16px; line-height: 24px; font-weight: bold;">
        I am excited for you to be in this journey with me.
      </p>
      <p style="margin: 0; font-size: 16px; line-height: 24px;">
        This is the first product in order to power-up your launches and
        surround you with potential for success.
      </p>
    </div>
  );
}
