import { colors } from "../tokens";
import { EmailCompany } from "./company";

const URL = import.meta.env.VITE_DEPLOY_URL;

export function EmailFooter() {
  return (
    <div
      style={`padding: 30px; background: ${colors.mutedColor}; color: #000;`}
    >
      <div style="text-align: center;">
        <a
          href="https://atila.io/bsky"
          rel="noopener"
          target="_blank"
          style={`color: currentColor; text-decoration: none; margin: 0 10px;`}
        >
          <img
            src={`${URL}/bluesky.png`}
            alt="BlueSky"
            style={{
              height: "24px",
              width: "24px",
              border: 0,
              display: "inline-block",
            }}
          />
        </a>
        <a
          href="https://atila.io/x"
          rel="noopener"
          target="_blank"
          style={{
            color: "currentColor",
            "text-decoration": "none",
            margin: "0 10px",
          }}
        >
          <img
            src={`${URL}/xicon.png`}
            alt="X (Formerly Twitter)"
            style={{
              height: "24px",
              width: "24px",
              border: 0,
              display: "inline-block",
            }}
          />
        </a>
        <a
          href="https://atila.io/linkedin"
          rel="noopener"
          target="_blank"
          style={`color: currentColor; text-decoration: none; margin: 0 10px;`}
        >
          <img
            src={`${URL}/linkedin.png`}
            alt="LinkedIn"
            style={{
              height: "24px",
              width: "24px",
              border: 0,
              display: "inline-block",
            }}
          />
        </a>
        <a
          href="https://atila.io/github"
          rel="noopener"
          target="_blank"
          style={`color: currentColor; text-decoration: none; margin: 0 10px;`}
        >
          <img
            src={`${URL}/github.png`}
            alt="GitHub"
            style={{
              height: "24px",
              width: "24px",
              border: 0,
              display: "inline-block",
            }}
          />
        </a>
      </div>
      <div style="margin-bottom: 20px;">
        <p style="margin-top: 32px; font-size: 14px; line-height: 16px; text-align: center">
          &copy; <EmailCompany />, {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
}
