import { colors } from "../tokens";

export function EmailCompany() {
  return (
    <a
      href="https://atila.io"
      rel="noopener"
      target="_blank"
      style={{ color: colors.text, "text-decoration": "none" }}
    >
      Atila.io
    </a>
  );
}
