import { colors } from "../tokens";

interface Props {
  href: string;
  children: string;
}

export function EmailCTA(props: Props) {
  return (
    <div style="text-align: center; margin: 42px 0;">
      <a
        href={props.href}
        class="cta-button"
        style={{
          "background-color": colors.accentColor,
          color: colors.accentText,
          "text-decoration": "none",
          padding: "14px 28px",
          "font-size": "20px",
          "font-weight": "bold",
          "border-radius": "5px",
          display: "inline-block",
        }}
      >
        {props.children}
      </a>
    </div>
  );
}
